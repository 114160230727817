import styled from "@emotion/styled";
import Colors from "/styles/colors";
import { GAINSBORO_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import { MAX_DESKTOP_GRID_WIDTH } from "constants/vp-sizes";
export const StyledMultiCardsCarouselTabsComponent = styled.div`
  margin: 0 auto;

  .sliding-card-grid-component {
    max-width: unset;

    .main-card-grid {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cards-row {
        display: flex;
        justify-content: center;
        gap: 20px;
        padding: 10px;

        .small-card {
          width: 292px;
          height: 282px;
          background-color: ${Colors[WHITE_COLOR_NAME]};
          text-align: center;
          perspective: 1000px;
          border-radius: 16px;

          .card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            border: 1px solid ${Colors[GAINSBORO_COLOR_NAME]};
            border-radius: 16px;
          }

          &:hover .card-inner {
            transform: rotateY(180deg);
          }

          .card-front,
          .card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            border-radius: 16px;
            overflow: hidden;
          }

          .card-back {
            transform: rotateY(180deg);
          }

          .picture-component {
            border-radius: 14px;
            display: flex;

            img {
              width: 100%;
            }
          }
        }

        .wide-card {
          width: 600px;
          height: 282px;
          border: 1px solid ${Colors[GAINSBORO_COLOR_NAME]};
          border-radius: 16px;
          padding: 24px 40px;

          .card-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .image-wrapper .picture-component {
              display: flex;
              img {
                ${({ wideCardImageWidth })=>wideCardImageWidth ? `width: ${wideCardImageWidth}` : ""};
              }
            }
          }

          .text-wrapper {
            display: flex;
            align-items: flex-end;
            color: ${Colors[WHITE_COLOR_NAME]};

            .paragraph-wrapper {
              .paragraph-body {
                ${({ wideCardTextSize })=>wideCardTextSize ? `font-size: ${wideCardTextSize}` : ""};
                color: ${Colors[WHITE_COLOR_NAME]};
                line-height: 1.4;
              }
            }
          }
        }
      }

      .cards-row:first-of-type {
        padding-top: 20px;
      }

      .cards-row:last-of-type {
        padding-bottom: 20px;
      }
    }

    .carousel-title-component {
      align-items: flex-end;
      justify-content: center;

      .core-title-container .core-title {
        width: 1120px;
        font-weight: 200;
        font-size: 6rem;
        line-height: 1.1;
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_GRID_WIDTH}px) {
    .sliding-card-grid-component {
      .carousel-title-component .core-title-container .core-title {
        width: 880px;
        font-size: 5rem;
      }

      .main-card-grid {
        .cards-row {
          .small-card {
            width: 234px;
            height: 226px;
          }

          .wide-card {
            width: 476px;
            height: 226px;
            .card-content .text-wrapper .paragraph-wrapper .paragraph-body {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
`;
